export default function onUnload(fn) {
  // source
  // https://guwii.com/cache-issues-with-forwards-and-back-history-in-safari/
  window.addEventListener("pagehide", fn);
  window.addEventListener("pageshow", fn);

  var dispose = () => {
    window.removeEventListener("pagehide", fn);
    window.removeEventListener("pageshow", fn);
  }

  return dispose;
}