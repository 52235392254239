import AppointSpaceLogo from '~/assets/svg/appoint-space-logo.svg';
import GoogleLogo from '~/assets/svg/google-logo.svg'
import FacebookLogo from '~/assets/svg/facebook-logo.svg'
import KakaoLogo from '~/assets/svg/kakao-logo.svg'
import LineLogo from '~/assets/svg/line-logo.svg'
import EmailIcon from '~/assets/svg/email-icon.svg'

import Disclaimer from './disclaimer.js';
import OAuthButton from './o-auth-button.js'
import CredentialsLayout from '~/components/layouts/credentials-layout.js';
import Mistake from './mistake.js';
import State from './credentials-state.js';
import { Component } from "preact";
import { watchClassComponent } from '~/forms/utils/hyperactiv.js';

import {
  BrandContainer as BrandContainerCN,
  AppointSpaceLogo as AppointSpaceLogoCN,
  H1 as H1CN,
  Title as TitleCN,
  P as PCN,
  Subtitle as SubtitleCN,
  OAuthButtonsGroup as OAuthButtonsGroupCN,
  SNSLogo as SNSLogoCN,
} from './sign-in.css';

export default watchClassComponent(class SignIn extends Component {

  componentWillMount() {
    this.reactor = State();
  }

  componentWillUnmount() {
    // this.reactor.dispose();
  }

  render() {

    return (
      <CredentialsLayout size={'small'}>
        <div class={BrandContainerCN}>
          <a class={AppointSpaceLogoCN} href="/">
            <img style={{ width: '100%', height: '100%' }} src={AppointSpaceLogo} />
          </a>
        </div>
        <div class={`${H1CN} ${TitleCN}`}>
          Sign up
        </div>
        <div class={`${PCN} ${SubtitleCN}`}>
          to make appointments
        </div>


        <div class={OAuthButtonsGroupCN}>
          <OAuthButton reactor={this.reactor.google}>
            <img class={SNSLogoCN} src={GoogleLogo} />
            <div>Sign up with Google</div>
          </OAuthButton>
          <OAuthButton reactor={this.reactor.facebook}>
            <img class={SNSLogoCN} src={FacebookLogo} />
            <div>Sign up with Facebook</div>
          </OAuthButton>
          <OAuthButton reactor={this.reactor.kakao}>
            <img class={SNSLogoCN} src={KakaoLogo} />
            <div>Sign up with Kakao</div>
          </OAuthButton>
          <OAuthButton reactor={this.reactor.line}>
            <img class={SNSLogoCN} src={LineLogo} />
            <div>Sign up with Line</div>
          </OAuthButton>
          <a href="/auth/sign-up/password" class="blank">
            <OAuthButton reactor={this.reactor.password}>
              <img class={SNSLogoCN} src={EmailIcon} />
              <div>Sign up with a password</div>
            </OAuthButton>
          </a>
        </div>

        <Mistake>Already have an account? <a href="/auth/sign-in">Sign in</a></Mistake>

        <Disclaimer />

      </CredentialsLayout>
    )
  }
})
