import { computed } from "hyperactiv/src/computed";

export function SmartValidate(validator, reactor) {

    var timeout = null;

    function hardValidate(reactor) {
        var error = validator(reactor().value) || null;
        reactor().error = error;
        return error;
    }

    function onInput(reactor) {
        reactor().error = null;

        if (!reactor().value) {
            return;
        }

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            hardValidate(reactor);
        }, 1000)
    }

    return {
        reaction: computed(() => {
            onInput(reactor);
        }),
        hardValidate: hardValidate.bind(null, reactor),
    }
}

export function getEmailRegex() {
    return /\S+@\S+\.\S+/;
}