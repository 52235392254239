import { Component } from "preact";
import Error from "~/components/error";
import Redirect from "~/components/utils/redirect";
import { watchClassComponent } from "~/forms/utils/hyperactiv";
import store from "~/store";

export default watchClassComponent(class Authorized extends Component {

  componentWillMount() {
    store.currentUserId.load();
  }

  render() {
    switch (store.currentUserId.state) {
      case 'authorized': {
        return this.props.children;
      }
      case 'unauthorized': {
        return <Redirect to="/auth/sign-in" />
      }
      case 'loading': {
        return 'loading...'
      }
      case 'error': {
        return <Error error={store.error.value}></Error>
      }
    }
  }
})