import { Component } from 'preact';
import { route } from 'preact-router';

export default class Redirect extends Component {
  componentWillMount() {
    if (this.props.to.startsWith('http')) { // simplistic
      window.location.href = this.props.to;
      return
    }

    route(this.props.to, true);
  }

  render() {
    return null;
  }
}