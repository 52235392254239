import { Component } from 'preact';
import { dispose, observe, watchClassComponent } from '~/forms/utils/hyperactiv';
import { SmartValidate } from '~/components/pages/auth/password-utils';
import store from '~/store';
import CredentialsLayout from '~/components/layouts/credentials-layout';
import StandardInput from '~/components/standard-input';
import Loader from '~/components/loader';
import Error from '~/components/error';
import ValidationError from '~/components/validation-error';
import api from '~/forms/utils/api';
import { EmailNag as EmailNagCN } from './email-nag.css';
import {
  H1 as H1CN,
  Title as TitleCN,
  P as PCN,
  Subtitle as SubtitleCN,
} from '~/components/pages/auth/sign-in.css';
import { Microlabel as MicrolabelCN } from '~/components/symlinked/forms/keynote/c/microlabel.css';
import nou from '~/forms/utils/nou';
import { Button as ButtonCN } from '~/components/symlinked/forms/keynote/c/button.css';
import { FormGroup as FormGroupCN, Group as GroupCN, CTA as CTACN } from '~/components/pages/auth/sign-in-with-password.css';
import clsx from '~/forms/utils/clsx';

export default watchClassComponent(class EmailNag extends Component {

  componentWillMount() {
    this.reactor = State();
  }

  componentWillUnmount() {
    this.reactor.dispose();
  }

  render() {
    var leReactor = this.reactor.reactor;

    return (
      <CredentialsLayout size="small" class={EmailNagCN}>
        <div class={`${PCN} ${SubtitleCN}`}>
          before we start
        </div>
        <div class={`${H1CN} ${TitleCN}`}>
          What is your name?
        </div>

        <form action="/register/password-managers/dummy" onSubmit={e => { e.preventDefault(); return false; }}>
          <fieldset class={FormGroupCN} disabled={leReactor.form.disabled}>

            <div class={GroupCN}>
              <div class={MicrolabelCN}>Name</div>
              <StandardInput state={leReactor.name.error && 'error'} type="text" name="name" placeholder="Your full name..." autocomplete="name" oninput={leReactor.name.onInput}></StandardInput>
              {leReactor.name.error ? <ValidationError>{leReactor.name.error}</ValidationError> : null}
            </div>

            <button class={clsx(CTACN, ButtonCN)} type="submit" onClick={leReactor.form.onSubmit}>{leReactor.form.loading ?
              <Loader color={"white"} class="medium" /> : 'Next'}</button>

            {leReactor.form.error ?
              <Error error={leReactor.form.error} /> : null}

          </fieldset>
        </form>

      </CredentialsLayout>
    )
  }
})

function State() {
  const reactor = observe({});

  reactor.form = {
    disabled: false,
    loading: false,
    error: null,
    onSubmit() {
      // local validation
      var anyError = validatees.map(v => v.hardValidate()).some(Boolean);
      if (anyError) {
        return;
      }

      loading();

      const payload = {
        id: (store.currentUserId.value),
        name: (reactor.name.value),
        _state: 'present',
      };
      api.request({
        url: 'users',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: payload,
      }).then(response => {
        store.users.set(response.id, response);
      }).catch(error => {
        reactor.form.error = error;
      }).finally(() => {
        ready();
      })
    }
  }
  reactor.name = {
    onInput(e) {
      reactor.name.value = e.target.value;
    },
    error: null,
    value: '',
  }

  var validatees = [
    SmartValidate(v => !/.{1,}/.test(`${nou(v) ? '' : v}`) && 'Please enter your name', () => reactor.name),
  ]

  const state = {
    reactor,
    dispose: () => {
      validatees.forEach(v => dispose(v.reaction));
    },
  }
  return state;

  function loading() {
    reactor.form.disabled = true;
    reactor.form.loading = true;
    reactor.form.error = null;
  }

  function ready() {
    reactor.form.disabled = false;
    reactor.form.loading = false;
  }

}