import { Button as ButtonCN } from '~/components/symlinked/forms/keynote/c/button.css';
import AppointSpaceLogo from '~/assets/svg/appoint-space-logo.svg';
import { route } from 'preact-router';
import Mistake from './mistake.js';
import { SmartValidate } from './password-utils.js';
import { Component } from 'preact';
import CredentialsLayout from '~/components/layouts/credentials-layout.js';
import Disclaimer from './disclaimer.js';
import {
  BrandContainer as BrandContainerCN,
  AppointSpaceLogo as AppointSpaceLogoCN,
  H1 as H1CN,
  Title as TitleCN,
  P as PCN,
  Subtitle as SubtitleCN,
} from './sign-in.css';
import { Microlabel as MicrolabelCN } from '~/components/symlinked/forms/keynote/c/microlabel.css';
import { FormGroup as FormGroupCN, Group as GroupCN, CTA as CTACN } from './sign-in-with-password.css';
import StandardInput from '~/components/standard-input.js';
import { dispose, observe, watchClassComponent } from '~/forms/utils/hyperactiv.js';
import ValidationError from '~/components/validation-error.js';
import Error from '~/components/error.js';
import Loader from '~/components/loader.js';
import nou from '~/forms/utils/nou.js';
import api from '~/forms/utils/api.js';
import get from '~/forms/utils/get.js';

// password managers
// https://www.chromium.org/developers/design-documents/create-amazing-password-forms

export default watchClassComponent(class SignInWithPassword extends Component {

  componentWillMount() {
    this.l = State();
  }

  componentWillUnmount() {
    // this.l.dispose();
  }

  render() {
    var l = this.l;

    return (
      <CredentialsLayout size={'small'}>
        <div class={BrandContainerCN}>
          <a class={AppointSpaceLogoCN} href="/">
            <img style={{ width: '100%', height: '100%' }} src={AppointSpaceLogo} />
          </a>
        </div>
        <div class={`${H1CN} ${TitleCN}`}>
          Sign in
        </div>
        <div class={`${PCN} ${SubtitleCN}`}>
          to see your workspace
        </div>

        <form action="/register/password-managers/dummy" onSubmit={e => { e.preventDefault(); return false; }}>
          <fieldset class={FormGroupCN} disabled={l.reactor.form.disabled}>
            <div class={GroupCN}>
              <div class={MicrolabelCN}>Email</div>
              <StandardInput state={l.reactor.username.error && 'error'} type="text" placeholder="your@email.com..." autocomplete="username" oninput={l.reactor.username.onInput}></StandardInput>
              {l.reactor.username.error ? <ValidationError>{l.reactor.username.error}</ValidationError> : null}
            </div>

            <div class={GroupCN}>
              <div class={MicrolabelCN}>Password</div>
              <StandardInput state={l.reactor.password.error && 'error'} type="password" placeholder="type your password..." autocomplete="current-password" oninput={l.reactor.password.onInput}></StandardInput>
              {l.reactor.password.error ? <ValidationError>{l.reactor.password.error}</ValidationError> : null}
            </div>

            <button class={`${CTACN} ${ButtonCN}`} type="submit" onClick={l.reactor.form.onSubmit}>{l.reactor.form.loading ? <Loader color={"white"} class="medium" /> : 'Sign in'}</button>

            {l.reactor.form.error ? <Error error={l.reactor.form.error} /> : null}

          </fieldset>
        </form>

        <Mistake>Don't have an account? <a href="/auth/sign-up/password">Sign up</a></Mistake>

        <Disclaimer />


      </CredentialsLayout>
    )
  }
})

function State() {
  const reactor = observe({});

  reactor.form = {
    disabled: false,
    loading: false,
    error: null,
    onSubmit() {
      // local validation
      var anyError = validatees.map(v => v.hardValidate()).some(Boolean);
      if (anyError) {
        return;
      }

      loading();

      const payload = {
        username: (reactor.username.value),
        password: (reactor.password.value),
      };
      api.request({
        url: 'auth/password/sign-in',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: payload,
      }).then(response => {
        return route('/');
      }).catch(error => {
        if (get(error, '0') === 'UNAUTHORIZED') {
          reactor.form.error = `The email or password is incorrect`;
        } else {
          reactor.form.error = error;
        }
      }).finally(() => {
        ready();
      })
    }
  }
  reactor.username = {
    onInput(e) {
      reactor.username.value = e.target.value;
    },
    error: null,
    value: '',
  }
  reactor.password = {
    onInput(e) {
      reactor.password.value = e.target.value;
    },
    error: null,
  }

  var validatees = [
    SmartValidate(v => !/.{1,}/.test(`${nou(v) ? '' : v}`) && 'Type your email', () => reactor.username),
    SmartValidate(v => !/.{1,}/.test(`${nou(v) ? '' : v}`) && 'Type your password', () => reactor.password),
  ]

  const state = {
    reactor,
    dispose: () => {
      validatees.forEach(v => dispose(v.reaction));
    },
  }
  return state;

  function loading() {
    reactor.form.disabled = true;
    reactor.form.loading = true;
    reactor.form.error = null;
  }

  function ready() {
    reactor.form.disabled = false;
    reactor.form.loading = false;
  }

}

