import api from './forms/utils/api';
import { computed, observe } from './forms/utils/hyperactiv';
import is from './utils/is';

var { createStore, $, $reaction, callReactions } = ReactiveFiniteStateMachine();
var store = createStore({

  error: $({
    value: null,
    set(store, value) {
      this.value = value;
    },
    unset() {
      this.value = null;
    },
  }),

  currentUserId: $({
    value: null,
    state: 'unloaded',
    abort: null,
    load({ users, error }) {
      switch (this.state) {

        case 'loading':
          this.abort();
        case 'unauthorized':
        // case 'authorized':
        case 'unloaded':
          console.log(`load cuid`, this.state);
          var abortController = new AbortController();
          this.abort = abortController.abort;

          this.state = 'loading';
          api.request({ url: 'users/me', signal: abortController.signal }).then(u => {
            if (!u) {
              // we have cookie but user was deleted on backend manually somehow
              this.state = 'unauthorized';
              return;
            }
            store.users.init(u.id).value = u;
            store.users.init(u.id).state = 'loaded';

            this.value = u.id;
            this.state = 'authorized';
          }).catch(e => {
            if (e[0] === 'UNAUTHORIZED') {
              this.state = 'unauthorized';
              return;
            } else {
              this.state = 'error';
              error.set(e);
            }
          }).finally(() => {
            this.abort = null;
          })
          break;
      }

    },
    change({ users }, id) {
      switch (this.state) {
        case 'loading':
          this.abort();
        // passthru
        default:
          this.value = null;
          this.state = 'loading';
          users.init(id).state = 'loading';

          var abortController = new AbortController();
          this.abort = abortController.abort;
          api.request({ url: 'admin/login/' + id })
            .then(u => {
              users[u.id] = ({
                value: u,
                state: 'loaded',
              })
              this.value = u.id;
              this.state = 'loaded';
            }).catch(e => {
              this.state = 'unloaded';
              error.set(e);
            }).finally(() => {
              this.abort = null;
            })
          break;
      }
    },
  }),

  users: $({
    init(store, id) {
      if (!this[id]) {
        this[id] = {
          value: null,
          state: 'unloaded',
          error: null,
          abort: null,
        }
      }
      return this[id];
    },
    set({ users }, id, value) {
      users[id].value = value;
      users[id].state = 'loaded';
    },
    loadUser({ error }, id) {
      var o = this.init(id);
      switch (o.state) {
        case 'loading':
          o.abort();
        case 'error':
        case 'unloaded':
          var abortController = new AbortController();
          o.abort = abortController.abort;
          o.state = 'loading';
          api.request({ url: 'users', method: 'post', body: { id } })
            .then(u => {
              this.set(id, u);
            }).catch(e => {
              o.state = 'error';
              o.error = e;
              error.set(e);
            }).finally(() => {
              o.abort = null;
            })
      }
    },
  }),

  currentWorkspaceId: $({
    value: null,
    state: 'unloaded',
    reaction: $reaction(({ workspaces }) => workspaces.state, ({ workspaces, workspacesOrder, currentWorkspaceId }) => {
      if (workspaces.state === 'loaded') {
        currentWorkspaceId.value = workspacesOrder.value[0];
        currentWorkspaceId.state = 'loaded';
      }
    }),
  }),

  workspacesOrder: observe({
    value: [],
    state: 'unloaded',
  }),

  workspaces: $({
    state: 'unloaded',
    abort: null,
    // reaction: $reaction(({ currentUserId }) => currentUserId.value, ({ workspaces }) => {
    //   workspaces.reload()
    // }),
    load({ error, workspacesOrder, workspaceUserIds }) {
      switch (this.state) {

        case 'loading':
          this.abort();
        // passthru
        // case 'loaded':
        case 'error':
        case 'unloaded':
          var abortController = new AbortController();
          this.abort = abortController.abort;

          workspacesOrder.value.length = 0;
          workspacesOrder.state = 'unloaded';
          this.state = 'loading';
          api.request({ url: 'workspaces', signal: abortController.signal }).then(wss => {
            wss.forEach(ws => {
              this[ws.id] = {
                value: ws,
                state: 'loaded',
              };
              workspacesOrder.value.push(ws.id);
              workspaceUserIds.load(ws.id);
            })
            workspacesOrder.state = 'loaded';

            this.state = 'loaded';
          }).catch(e => {
            this.state = 'unloaded';
            error.set(e);
          }).finally(() => {
            this.abort = null;
          })
          break;
      }

    }
  }),

  workspaceUserIds: $({
    init(store, id) {
      if (!this[id]) {
        this[id] = {
          value: [],
          state: 'unloaded',
          error: null,
          abort: null,
        }
      }
      return this[id];
    },
    load({ error, users }, id) {
      var o = this.init(id);
      switch (o.state) {
        case 'loading':
          o.abort();
        case 'error':
        // case 'loaded':
        case 'unloaded':
          var abortController = new AbortController();
          o.abort = abortController.abort;
          o.state = 'loading';
          api.request({ url: `workspaces/${id}/users` })
            .then(us => {
              o.value.length = 0;
              us.forEach(u => {
                users.init(u.id);
                users.set(u.id, u);
                o.value.push(u.id);
              })
              o.state = 'loaded';
            }).catch(e => {
              o.state = 'error';
              o.error = e;
              error.set(e);
            }).finally(() => {
              o.abort = null;
            })
      }
    },
  }),

  services: $({
    init(store, id) {
      if (!this[id]) {
        this[id] = {
          value: null,
          state: 'unloaded',
        }
      }
      return this[id];
    },
  }),

  workspaceServiceIds: $({
    reaction: $reaction(({ currentWorkspaceId }) => currentWorkspaceId.value, ({ workspaceServiceIds, currentWorkspaceId }) => {
      if (!is(currentWorkspaceId.value)) {
        return;
      }
      workspaceServiceIds.loadWorkspaceServices(currentWorkspaceId.value)
    }),
    init(store, workspaceId) {
      if (!this[workspaceId]) {
        this[workspaceId] = {
          value: [],
          state: 'unloaded',
        }
      }
      return this[workspaceId];
    },
    loadWorkspaceServices({ error, services }, workspaceId) {
      var o = this.init(workspaceId);
      switch (o.state) {
        case 'loading':
          o.abort();
        case 'error':
        // case 'loaded':
        case 'unloaded':
          var abortController = new AbortController();
          o.abort = abortController.abort;
          o.state = 'loading';
          api.request({ url: `workspaces/${workspaceId}/services` })
            .then(ss => {
              o.value.length = 0;
              ss.forEach(s => {
                o.value.push(s.id);
                services.init(s.id).value = s;
                services.init(s.id).state = 'loaded';
              })
              o.state = 'loaded';
            }).catch(e => {
              o.state = 'error';
              o.error = e;
              error.set(e);
            }).finally(() => {
              o.abort = null;
            })
      }
    },

  }),

  bookings: $({
    init(store, id) {
      if (!this[id]) {
        this[id] = {
          value: null,
          state: 'unloaded',
        }
      }
      return this[id];
    },
    set({ bookings }, id, value) {
      bookings[id].value = value;
      bookings[id].state = 'loaded';
    },
  }),

  userBookings: $({
    value: [],
    state: 'unloaded',
    abort: null,
    load({ bookings, error }, start, end) {
      if (this.state !== 'unloaded') {
        return;
      }
      this.state = 'loading';

      api.request({ url: `bookings`, qs: { start, end } })
        .then(bkngs => {
          bkngs.forEach(b => {
            this.value.push(b.id);
            bookings.init(b.id);
            bookings.set(b.id, b);
          })
          this.state = 'loaded';
        }).catch(e => {
          this.state = 'error';
          this.error = e;
          error.set(e);
        }).finally(() => {
          this.abort = null;
        })
    },
    // subscribe({ error }, start, end) {
    //   var socket = api.websocket({});

    //   socket.addEventListener('open', (event) => {
    //     console.log('ws opened');
    //     socket.send(JSON.stringify({
    //       type: 'subscribe.bookings',
    //       start,
    //       end,
    //     }));
    //   });

    //   socket.addEventListener('message', (event) => {
    //     var obj = JSON.parse(event.data);
    //     if (obj.type === 'booking') {
    //       if (obj.event === 'add') {
    //         this.value[obj.payload.id] = obj.payload;
    //       } else {
    //         error.set(`unknown event [${obj.event}]`);
    //       }
    //     } else {
    //       error.set(`unknown type [${obj.type}]`);
    //     }
    //   });
    // },
  })

})

function ReactiveFiniteStateMachine() {
  var store = {};

  var reactionsToCall = [];

  return {
    createStore,
    $,
    $reaction,
    callReactions,
  };

  function callReactions() {
    reactionsToCall.forEach(r => r());
  }

  function createStore(obj) {
    Object.assign(store, obj);
    return store;
  }

  function $reaction(watch, react, immediateRun) {
    // TODO bind this to workspaces? how?
    var toCall = computed(watch.bind(null, store), { callback: react.bind(null, store), autoRun: false });
    if (immediateRun) {
      toCall();
    } else {
      reactionsToCall.push(toCall);
      return toCall;
    }
  }

  function $(_obj) {
    // ! crucial: observe first to bind the observed
    var obj = observe(_obj, {
      deep: true,
      bind: false,
    });

    // bind root obj to first arg of the fns
    Object.keys(obj).forEach(k => {
      var v = obj[k];
      if (v instanceof Function) {
        // this is an action
        obj[k] = v.bind(obj /* bind to the observed obj */, store /* bind to store as 1st param */);
      }
    })

    return obj;
  }
}

callReactions();


window.store = store;
window.__store = store;
export default store;
