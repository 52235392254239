import api from '~/forms/utils/api';
import { observe } from '~/forms/utils/hyperactiv';
import onUnload from '~/utils/on-unload';

function State() {
  const reactor = observe({});

  var services = ['google', 'facebook', 'kakao', 'line', 'password']

  services
    .forEach(name => {
      reactor[name] = {
        disabled: false,
        error: null,
        loading: false,
        async onClick() {
          if (name === 'password') {
            return;
          }

          Object.values(reactor).forEach(r => {
            r.disabled = true;
            r.error = null;
            r.loading = false;
          });

          reactor[name].loading = true;
          await api.request({ url: `auth/${name}` }).then(result => {
            // problem 0
            // enableAll(); // ! Safari caches left page so on history back will look exactly the same as when leaving the page
            window.location.href = result.location;
          }).catch(error => {
            reactor[name].error = error;
            enableAll();
          })
        }
      }
    })

  function enableAll() {
    services.forEach(s => {
      reactor[s].loading = false;
      reactor[s].disabled = false;
    })
  }

  // problem 0 solution
  onUnload(() => enableAll());

  return reactor;
}

export default State;

