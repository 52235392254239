import { Loader as LoaderCN, Circle as CircleCN, SvgAnimation, CircleAnimation } from './loader.css';
import * as mod from './loader.css';
import clsx from '~/forms/utils/clsx';

export default p => {
  return (
    <svg class={clsx(LoaderCN, mod[p.class])} style={{ animationName: SvgAnimation }} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle class={CircleCN} cx="50" cy="50" r="45" style={{ stroke: p.color, animationName: CircleAnimation }} />
    </svg>
  )
}
