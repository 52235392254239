import Router from 'preact-router';

import SignIn from './pages/auth/sign-in.js';
import SignInWithPassword from './pages/auth/sign-in-with-password.js';
import SignUp from './pages/auth/sign-up.js';
import SignUpWithPassword from './pages/auth/sign-up-with-password.js';
import NotFound from './pages/not-found.js';
import Authorized from './pages/auth/authorized.js';
import Unauthorized from './pages/auth/unauthorized.js';
import MissingDataGateway from './pages/auth/missing-data-gateway.js';
import Appointments from './views/appointments.js';


export default p => {
    return (
        <Router>
            <SignUp path="/auth/sign-up" />
            <SignUpWithPassword path="/auth/sign-up/password" />
            <SignIn path="/auth/sign-in" />
            <SignInWithPassword path="/auth/sign-in/password" />

            <Authorized path="/" >
                <MissingDataGateway>
                    <Router>
                        <Appointments path="/" />
                    </Router>
                </MissingDataGateway>
            </Authorized>
            <NotFound default />
        </Router>
    )
}