import dayjs from "dayjs";
import { watchFunctionalComponent } from "~/forms/utils/hyperactiv"
import store from "~/store"
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import plLocale from 'dayjs/locale/pl';
import { Microlabel as MicrolabelCN } from '~/components/symlinked/forms/keynote/c/microlabel.css';
import { HourGroupingRow, AppointmentDescription, AppointmentTimeSpan as AppointmentTimeSpanCN, Container, AppointmentList, Appointment, Hour } from './appointments.css';

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.updateLocale('en-gb', plLocale);
dayjs.locale('en-gb');

export default watchFunctionalComponent(p => {

    if (store.userBookings.state === 'unloaded') {
        var now = dayjs();
        var start = now.unix();
        var end = now.add(7, 'days').unix();
        // store.userBookings.load(start, end);
        store.userBookings.load(start, end);
        return 'loading...';
    }

    var { userBookings, bookings } = store;

    var days = [];

    for (var i = 0; i < userBookings.value.length; i++) {
        var id = userBookings.value[i];
        var b = bookings[id].value;

        var djs = dayjs.unix(b.start);
        var dayText = djs.format('LL');
        var day = updateItems(days, dayText);

        var hourText = djs.set('minutes', 0).format('LT');
        var hour = updateItems(day.items, hourText);

        hour.items.push({
            times: dayjs.unix(b.start).format('LT') + ' - ' + dayjs.unix(b.end).format('LT'),
            items: b.items,
            response: b.response,
        })
    }

    function updateItems(days, dayText) {
        var ld = days[days.length - 1];
        if (ld) {
            if (ld.text === dayText) {
                // use last day
                return ld;
            } else {
                return make();
            }
        } else {
            return make();
        }

        function make() {
            // make new bin
            var d = {
                text: dayText,
                items: [],
            };
            days.push(d)
            return d;
        }
    }

    return (
        <div class={Container}>
            {/* <div class={MicrolabelCN}>Appointments</div> */}
            {days.map(day => {
                return (
                    <div key={day.text} class={''}>
                        <div class={MicrolabelCN}>{day.text}</div>
                        {day.items.map(hour => {
                            return (
                                <div class={HourGroupingRow}>
                                    <div class={Hour}>{hour.text}</div>

                                    <div class={AppointmentList}>
                                        {hour.items.map(a => {
                                            return (
                                                <div class={Appointment}>
                                                    <div class={AppointmentTimeSpanCN}>
                                                        {a.times}
                                                    </div>
                                                    <div class={AppointmentDescription}>
                                                        {renderSubmission(a)}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                )
            })}
        </div>
    )
})

function renderSubmission(a) {
    return (
        <>
            {
                Object.entries(a.response).map(([type, v]) => {
                    switch (type) {
                        case 'dailyCodeGenerator':
                            return (
                                <div>
                                    <div>Kod: #{v.code}</div>

                                </div>
                            )
                    }

                })
            }
            {a.items.map(i => {
                switch (i.type) {
                    // case 'single-choice':
                    //     return (
                    //         <div>
                    //             <div>{i.text}</div>
                    //         </div>
                    //     )
                    case 'phone-number-input':
                        return (
                            <div>
                                {/* <div class={MicrolabelCN}>{i.title}</div> */}
                                <div>{i.title}: {i.text}</div>
                            </div>
                        )
                    case 'single-line-text-input':
                        return (
                            <div>
                                {/* <div class={MicrolabelCN}>{i.title}</div> */}
                                <div>{i.title}: {i.text}</div>
                            </div>
                        )
                }
            })}
        </>
    )
}