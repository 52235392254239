import isString from '~/utils/is-string';

import { ErrorBox as ErrorBoxCN, Header as HeaderCN, Stack as StackCN } from './error-box.css';

export default p => {

    if (p.error === null) {
        var message = 'null (as value)';
    } else if (p.error === undefined) {
        var message = 'undefined (as value)';
    } else if (p.error instanceof Error) {
        var { message, stack } = p.error;
    } else if (isString(p.error)) {
        var message = p.error;
        var stack = null;
    } else if (typeof p.error === 'object') {
        // json object?
        try {
            var message = JSON.stringify(p.error, null, 2);
            message = `<code><pre>${message}</pre></code>`;
        } catch (error) {
            var message = `${p.error}`; // give up
        }
    } else {
        var message = `${p.error}`; // give up
    }

    if (message) {
        // message = message.replace(/\n/g, '<br />');
    }
    if (stack) {
        stack = stack.replace(/\n/g, '<br />')
    }

    return (
        <div class={ErrorBoxCN}>
            <div class={HeaderCN} dangerouslySetInnerHTML={{ __html: message }} />
            {stack ? <div class={StackCN} dangerouslySetInnerHTML={{ __html: stack }} /> : null}
        </div>
    )
}
