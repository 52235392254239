import store from '~/store.js';
import EmailNag from '~/components/views/nags/email-nag.js';
import NameNag from '~/components/views/nags/name-nag.js';
import { Component } from 'preact';
import get from '~/forms/utils/get';
import { watchClassComponent } from '~/forms/utils/hyperactiv';

export default watchClassComponent(class MissingDataGateway extends Component {

  render(props) {
    store.currentUserId.load();
    var id = store.currentUserId.value;
    var user = get(store, ['users', id], {});
    // console.log(`user=`, user);

    if (user.state !== 'loaded') {
      return props.children;
    }

    if (!user.value.email) {
      return <EmailNag />
    }

    if (!user.value.name) {
      return <NameNag />
    }

    return props.children;
  }

})