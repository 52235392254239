import clsx from '~/forms/utils/clsx';
import { CredentialsLayout as CredentialsLayoutCN } from './credentials-layout.css';
import * as mod from './credentials-layout.css';

export default (props) => {
  return (
    <div class={clsx(CredentialsLayoutCN, mod[props.size], props.class)}>
      {props.children}
    </div>
  )
}
