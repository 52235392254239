import Loader from '~/components/loader.js';
import Error from '~/components/error.js';
import clsx from '~/forms/utils/clsx';
import { Button as ButtonCN } from '~/components/symlinked/forms/keynote/c/button.css';

import { OAuthButton as OAuthButtonCN, ButtonContent as ButtonContentCN } from './o-auth-button.css';

export default p => {
    var { reactor } = p;
    return (
        <div>
            <button class={clsx(OAuthButtonCN, ButtonCN, reactor.loading && "loading")} onClick={reactor.onClick} disabled={reactor.disabled} >
                <div class={ButtonContentCN}>
                    {p.children}
                </div>
                {reactor.loading ? <Loader class="medium" /> : null}
            </button>
            {reactor.error ? <Error error={reactor.error} /> : null}
        </div>
    )
}
